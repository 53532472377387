//wp-content\reactpress\apps\metalball

import * as THREE from 'three'
import { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { MarchingCubes, MarchingCube, MeshTransmissionMaterial, Environment, Bounds, Text } from '@react-three/drei'
import { Physics, RigidBody, BallCollider } from '@react-three/rapier'

function MetaBall({ color, vec = new THREE.Vector3(), ...props }) {
  const api = useRef()
  useFrame((state, delta) => {
    delta = Math.min(delta, 0.1)
    api.current.applyImpulse(
      vec
        .copy(api.current.translation())
        .normalize()
        .multiplyScalar(delta * -0.05),
    )
  })
  return (
    <RigidBody ref={api} colliders={false} linearDamping={4} angularDamping={0.95} {...props}>
      <MarchingCube strength={0.2} subtract={4} color={color} />
      <mesh>
      </mesh>
      <BallCollider args={[0.1]} type="dynamic" />
    </RigidBody>
  )
}

function Pointer({ vec = new THREE.Vector3() }) {
  const ref = useRef()
  useFrame(({ pointer, viewport }) => {
    const { width, height } = viewport.getCurrentViewport()
    vec.set(pointer.x * (width / 2), pointer.y * (height / 2), 0)
    ref.current.setNextKinematicTranslation(vec)
  })
  return (
    <RigidBody type="kinematicPosition" colliders={false} ref={ref}>
      <MarchingCube strength={0.5} subtract={10} color="black" />
      <mesh>
      </mesh>
      <BallCollider args={[0.1]} type="dynamic" />
    </RigidBody>
  )
}

export default function App() {
  return (
    <Canvas dpr={[1, 1.5]} camera={{ position: [0, 0, 5], fov: 25 }}>
      <color attach="background" args={['#000000']} />
      <ambientLight intensity={0.0} />
      <Physics gravity={[0, 0, 0]}>
        <MarchingCubes resolution={50} maxPolyCount={4000} enableUvs={false} enableColors>
          <MeshTransmissionMaterial
            vertexColors
            transmissionSampler
            transmission={1.2}
            thickness={0.0}
            roughness={0.0}
            chromaticAberration={0.0}
            anisotropy={0.0}
            envMapIntensity={1.5}
            distortion={1.0}
            distortionScale={0.7}
            temporalDistortion={0.0}
            iridescence={0}
            iridescenceIOR={0}
            iridescenceThicknessRange={[0, 0]}
            toneMapped={true}
          />
          <MetaBall color="#61CAF4" position={[-1, -1, -0.5]} />
          <MetaBall color="#B483B5" position={[-2, -2, 0.5]} />
          <MetaBall color="#EB478D" position={[1, 2, 0.5]} />
          <MetaBall color="#2B409A" position={[0, 3, 0.5]} />
          <Pointer />
        </MarchingCubes>
      </Physics>
      <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/industrial_workshop_foundry_1k.hdr" />
      {/* Zoom to fit a 1/1/1 box to match the marching cubes */}
      <Bounds >
        <mesh visible={false}>
          <boxGeometry />
        </mesh>
      </Bounds>
    </Canvas>
  )
}
